import $ from 'jquery';
import L from 'leaflet';

import api from '../../api';
// Widgets
import initSelect2 from '../../widgets/Select2';
import initCountrySelects from '../../widgets/CountrySelects';
// Utilities
import {createMap} from '../../utils/CreateMap';
import renderDatePicker from '../../utils/datepicker';
import {unsavedChangesMessage} from "./index";


function initMap(lat, lng) {
    // If the Site has location, center on that and zoom close
    const hasLocation = !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng));
    const map = createMap('site-edit-location-map');

    if (hasLocation) {
        map.setView([lat, lng], 15);
    } else {
        // center on Estonia
        map.setView([58.8, 25.5], 7);
    }

    const setValue = (inputId, coord) => {
        document.getElementById(inputId).value = coord;
        window.onbeforeunload = () => unsavedChangesMessage;
    };
    const getValue = inputId => document.getElementById(inputId).value;

    let marker = null;
    let hasMarkerLocationChanged = false;

    const createMarker = (latlng) => {
        const m = L.marker([...latlng], {
            draggable: 'true',
        }).addTo(map);
        m.on('move', e => {
            setValue('id_location_lat', e.latlng.lat);
            setValue('id_location_lng', e.latlng.lng);
        });
        m.on('dragstart', () => {
            hasMarkerLocationChanged = true;
        });
        return m;
    };

    async function getNewMarkerPosition() {
        const cadasterId = getValue('id_possession-cadaster_id');

        try {
            const response = await api.coordinates.fetch(null, {cadaster_id: cadasterId});

            let longitude = response.longitude;
            let latitude = response.latitude;

            if (longitude && latitude) {
                setValue('id_location_lat', latitude);
                setValue('id_location_lng', longitude);

                latitude = parseFloat(latitude);
                longitude = parseFloat(longitude);

                map.setView([latitude, longitude], 15);

                if (!marker) {
                    marker = createMarker([latitude, longitude]);
                } else {
                    marker.setLatLng([latitude, longitude]);
                }
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    if (hasLocation) {
        marker = createMarker([parseFloat(getValue('id_location_lat')), parseFloat(getValue('id_location_lng'))]);
    }

    document.getElementById('update-coordinates-link').onclick = e => {
        e.preventDefault();
        getNewMarkerPosition();
        hasMarkerLocationChanged = false;
    };

    document.getElementById('id_possession-cadaster_id').onchange = () => {
        if (!hasMarkerLocationChanged) {
            getNewMarkerPosition();
        }
    };
}

export default function initInfo(lat, lng) {
    const previousOwnerCountryId = "#id_possession-previous_owner_country_code";
    initCountrySelects('#id_country_code', '#id_county', '#id_parish');
    initSelect2({selector: `.select:not(:has(${previousOwnerCountryId}))`, placeholder: ''});
    // Initially `allowClear` was hardcoded to false, so we assume this is intentional, so we only allow this one field,
    // that we want to be blankable, to be cleared. Select2 does not allow empty options.
    initSelect2({selector: previousOwnerCountryId, placeholder: '', allowClear: true});
    renderDatePicker('block_start');

    // 2  - (TYPE_NOTICE, "Metsateatis")
    // In Site Edit/Create Form we don't default to today's date if type is 'Metsateatis' (type 2)
    const defaultToTodayIfNoValue = !(document.getElementById('id_possession-type') && document.getElementById('id_possession-type').value === '2');

    renderDatePicker(
        'possession-document_date',
        'Vali kuupäev',
        defaultToTodayIfNoValue,
    );

    initMap(lat, lng);

}
