import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {combineReducers, createStore} from 'redux';

import {composeEnhancers, getMiddleware} from '../../utils/middleware';
import {
    setMyDestinations,
    setMasterDestinations,
    selectDestination,
    setInactiveDestinations,
} from '../../actions';
import {
    selectedDestinationsReducer,
    myDestinationsReducer,
    masterDestinationsReducer,
    selectedDestinationReducer,
    inactiveDestinationReducer,
    siteDestinationsDataReducer,
} from '../../reducers';
import {
    DestinationInfoModal,
    DestinationResults,
    DestinationSelect,
} from '../../components/site_edit';

export default () => {
    const searchContainer = document.getElementById('site-edit-destination-search');
    const resultsContainer = document.getElementById('site-edit-destination-results');
    const modalContainer = document.getElementById('site-edit-destination-info-modal');

    if (!resultsContainer || !searchContainer || !modalContainer) {
        return;
    }

    const reducers = combineReducers({
        selected: selectedDestinationsReducer,
        myDestinations: myDestinationsReducer,
        masterDestinations: masterDestinationsReducer,
        inactiveDestinations: inactiveDestinationReducer,
        selectedDestination: selectedDestinationReducer,
        updatedDestinations: siteDestinationsDataReducer,
    });
    const store = createStore(reducers, composeEnhancers(getMiddleware()));

    store.dispatch(setMyDestinations(...DJ_CONST.MY_DESTINATIONS));
    store.dispatch(setMasterDestinations(...DJ_CONST.MASTER_DESTINATIONS));
    store.dispatch(selectDestination(...DJ_CONST.SELECTED_DESTINATIONS));
    store.dispatch(setInactiveDestinations(...DJ_CONST.INACTIVE_DESTINATIONS));

    ReactDOM.render(
        <Provider store={store}>
            <DestinationSelect showEpmkDestinationSelect={DJ_CONST.SITE_COUNTRY_CODE === "EE"} />
        </Provider>,
        searchContainer,
    );

    ReactDOM.render(
        <Provider store={store}>
            <DestinationResults />
        </Provider>,
        resultsContainer,
    );

    ReactDOM.render(
        <Provider store={store}>
            <DestinationInfoModal />
        </Provider>,
        modalContainer,
    );
};
