import $ from 'jquery';
import Select2 from "./Select2";

// Must be kept consistent with storage.forms.COUNTIES
const supportedCountryCodes = ["EE", "LT"];

function CountrySelects(countryFieldId, countyFieldId, parishFieldId) {
    /*
    This function is used to dynamically change the county and parish field types based on the selected country.
    To change field type from select -> input or vice versa, we found it better to just replace the whole field,
    instead of manipulating the existing. Thus, we will not set county and parish selectors to variables,
    so we'll always have the correct selector.
    */

    let countrySelect = $(countryFieldId);
    const select2ContainerClass = ".select2-container";

    // So when we switch back to select, we can use the original fields
    const originalCountyField = $(countyFieldId).clone();
    const originalParishField = $(parishFieldId).clone();

    let countryCode = countrySelect.val();
    let county = $(countyFieldId).val();
    let parish = $(parishFieldId).val();

    let countySavedOptions = [];
    let parishSavedOptions = [];

    // Some places we need to use the parent div of the field
    const selectDiv = fieldId => {
        return `#div_${fieldId.slice(1)}`;
    };

    const switchToInput = select => {
        const input = '<input type="text" name="' + select.attr('name') + '" maxlength="32" class="textinput textInput form-control" required="" id="' + select.attr('id') + '">';
        select.replaceWith(input);
        return $('#' + select.attr("id"));
    };

    const switchToSelect = (select, originalField) => {
        originalField.attr("name", select.attr("name"));
        select.replaceWith(originalField);
        return $('#' + select.attr('id'));
    };

    // They're recreated when Select2 is initialized
    const removeSelect2Containers = () => {
        $(selectDiv(countyFieldId)).find(select2ContainerClass).remove();
        $(selectDiv(parishFieldId)).find(select2ContainerClass).remove();
    };

    const getSavedOptions = (options, matcher) => {
        return options.filter(obj => obj.matcher === matcher);
    };

    const getSplitOptions = (select, matcher) => {
        let options = [];

        select.find('option').each(function optionSplit() {
            if ($(this).text().length) {
                const value = $(this).val();
                const splitText = $(this).text().split(':');
                const text = splitText[1];
                const matcherValue = splitText[0];
                options.push({value, matcher: matcherValue, text});
                matcherValue === matcher ? $(this).text(text) : $(this).remove();
            }
        });

        return options;
    };

    const getOptions = (select, savedOptions, matcher) => {
        let fieldOptions = [];

        if (savedOptions && savedOptions.length > 0) {
            fieldOptions = getSavedOptions(savedOptions, matcher);
        } else {
            fieldOptions = getSplitOptions(select, matcher);
        }

        return fieldOptions;
    };

    const saveOptions = (countyOptions, parishOptions) => {
        // Save original options because we will be removing them with selects when country changes
        if (countySavedOptions && countySavedOptions.length === 0) {
            countySavedOptions = countyOptions;
        }
        if (parishSavedOptions && parishSavedOptions.length === 0) {
            parishSavedOptions = parishOptions;
        }
    };

    const setOptions = (select, matcher, availableOptions) => {
        const options = availableOptions && availableOptions.length > 0 ? availableOptions : select.data('options');
        $.each(options, (index, option) => {
            if (!option.matcher.indexOf(matcher)) {
                select.append(`<option value="${option.value}">${option.text}</option>`);
            }
        });
    };

    const setData = () => {
        $(countyFieldId).data('options', getOptions($(countyFieldId), countySavedOptions, countrySelect.val()));
        $(parishFieldId).data('options', getOptions($(parishFieldId), parishSavedOptions, $(countyFieldId).val()));
    };

    const clearField = select => {
        select.html('<option value="" selected></option>');
        select.trigger('change');
    };

    const toggleMetsaregisterVisibility = (countryCode, countrySelect) => {
        const metsaregisterButton = $("#site-possession-dropdown");
        countrySelect.val() === 'EE' ? metsaregisterButton.show() : metsaregisterButton.hide();
    };

    // By default, they are select fields, so when existing object's country is unsupported,
    // then we switch to input and restore the values
    if (!supportedCountryCodes.includes(countrySelect.val())) {
        switchToInput($(countyFieldId));
        switchToInput($(parishFieldId));
        $(countyFieldId).val(county);
        $(parishFieldId).val(parish);
        removeSelect2Containers();
    }

    setData();
    saveOptions($(countyFieldId).data('options'), $(parishFieldId).data('options'));
    toggleMetsaregisterVisibility(countryCode, countrySelect);

    countrySelect.on("change", function () {
        countryCode = $(this).val();
        saveOptions($(countyFieldId).data('options'), $(parishFieldId).data('options'));

        // Let's do the switcheroo depending on the country
        if (supportedCountryCodes.includes(countryCode)) {
            switchToSelect($(countyFieldId), originalCountyField);
            switchToSelect($(parishFieldId), originalParishField);
            removeSelect2Containers();
            Select2({placeholder: "", selector: countyFieldId});
            Select2({placeholder: "", selector: parishFieldId});
            setData();
        } else {
            switchToInput($(countyFieldId));
            switchToInput($(parishFieldId));
            removeSelect2Containers();
        }

        // When country changes, we need to clear both county and parish fields
        clearField($(countyFieldId));
        clearField($(parishFieldId));

        if (countryCode && !countryCode.length) {
            return false;
        }

        setOptions($(countyFieldId), countryCode, getOptions($(countyFieldId), countySavedOptions, countryCode));
        toggleMetsaregisterVisibility(countryCode, countrySelect);
    });

    // County field listener. Since we recreated the county select, we can't use the same selector.
    $(selectDiv(countyFieldId)).on('change', countyFieldId, function onChange() { // eslint-disable-line consistent-return
        county = $(this).val();
        clearField($(parishFieldId));

        if (county && !county.length) {
            return false;
        }

        setOptions($(parishFieldId), county, getOptions($(parishFieldId), parishSavedOptions, county));
    });
}

export default CountrySelects;
